import React, { useRef, useEffect } from "react";
import { useMasterContext } from '../components/MasterContext';
import { CustomSelect } from '../components/CustomSelect';
import { PortfolioData } from "../data/PortfolioData";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import emailjs from 'emailjs-com';
import { Helmet } from "react-helmet";
import $ from 'jquery';

export const Home = () => {
    const form = useRef();
    const { setloader } = useMasterContext();
    const navigate = useNavigate();

    useEffect(() => {
        $('.form-control').each(function () {
            $(this).toggleClass("valid", this.value !== "");
        });
        $('.form-control').change(function () {
            $(this).toggleClass("valid", this.value !== "");
        });
    }, []);

    const validateForm = () => {
        let isInvalid = null;
        $('.InptFld input').each(function () {
            if ($(this).val().trim() === '') {
                isInvalid = this;
                return false;
            }
        });

        const invalidLabel = isInvalid ? $(isInvalid).siblings('label').text() : null;
        return { isInvalid, invalidLabel };
    };

    const sendEmail = (e) => {
        e.preventDefault();
        const { isInvalid, invalidLabel } = validateForm();
        if (isInvalid === null) {
            setloader(true);
            emailjs.sendForm('service_4uu6zlq', 'template_ykjlkik', form.current, 'ndVVqni1uJxBnHQF4')
                .then((result) => {
                    form.current.reset();
                    $('.InptFld input,textarea').each(function () {
                        $(this).removeClass('valid');
                    });
                    // Navigate to the thank you page with the formSubmitted state
                    navigate('/thank-you', { state: { formSubmitted: true } });
                    setloader(null);
                })
                .catch((error) => {
                    $('#form1 .btn').after(`<p class="message">Something Went Wrong!</p>`);
                    setloader(null);
                });
        } else {
            $(isInvalid).focus();
            alert(`Please enter ${invalidLabel}`);
        }
    };

    return (
        <>
            <Helmet>
                <title>CODEKMR</title>
                <meta name='description' content='codekmr portfolio featuring innovative web development projects, responsive designs, and effective backend solutions. Explore a collection of successful projects demonstrating expertise in frontend and backend development, API integration, and SEO strategies.'/>
                <meta name='keywords' content='web development, responsive design, frontend development, backend solutions, API integration, SEO strategies, portfolio, CODEKMR' />
            </Helmet>
            <main>
                <div className="banner home-banner">
                    <div className="container">
                        <div className="content">
                            <h1>Hello, I am<br /> Susheel Kumar</h1>
                            <p>I specialize in <b>building websites</b> that are visually stunning, intuitive to navigate, and optimized for speed, performance, and long-term success. My goal is to create seamless digital experiences that not only look great but also function flawlessly, driving engagement and achieving business objectives.</p>
                            <div className="btn">
                                <button onClick={() => document.querySelector('.homeSecA').scrollIntoView({ behavior: 'smooth' })}>View Portfolio</button>
                            </div>
                        </div>
                    </div>
                </div>
                <section >
                    <div className="homeSecA">
                        <div className="container">
                            <div className="model-body">
                                <div className="colA">
                                    <div className="heading">
                                        <h3>Skillset <span>Spotlight</span></h3>
                                    </div>
                                    <div className="col">
                                        <span>Language</span>
                                        <div className="col-md-sec">
                                            <div className="col-md">
                                                <h4>HTML</h4>
                                                <progress value={95} max={100} />
                                            </div>
                                            <div className="col-md">
                                                <h4>CSS(LESS,SCSS,SASS)</h4>
                                                <progress value={95} max={100} />
                                            </div>
                                            <div className="col-md">
                                                <h4>JS</h4>
                                                <progress value={85} max={100} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <span>Libraries</span>
                                        <div className="col-md-sec">
                                            <div className="col-md">
                                                <h4>ReactJS</h4>
                                                <progress value={85} max={100} />
                                            </div>
                                            <div className="col-md">
                                                <h4>jQuery</h4>
                                                <progress value={95} max={100} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col">
                                        <span>Framework</span>
                                        <div className="col-md-sec">
                                            <div className="col-md">
                                                <h4>Next.js</h4>
                                                <progress value={65} max={100} />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col">
                                        <span>Backend</span>
                                        <div className="col-md-sec">
                                            <div className="col-md">
                                                <h4>Django</h4>
                                                <progress value={85} max={100} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="colB">
                                    <div className="heading"><h3>About <span>Me</span></h3></div>
                                    <div className="WrapCol">
                                        <div className="headS">
                                            <div className="icon"><img src={require('../assets/images/image.jpg')} alt="website designing company" /></div>
                                            <div className="ttl">
                                                <h4>Susheel Kumar</h4>
                                                <p>Website Developer</p>
                                            </div>
                                        </div>
                                        <div className="content">
                                            <p>From Gurugram, Haryana, India</p>
                                            {/* <div className="flex">
                                                <div className="colms">
                                                    <p><b>Education:</b></p>
                                                    <p>Bachelor of Science in Computer</p>
                                                    <p>DPG Degree College, Gurugram</p>
                                                    <p>2021</p>
                                                </div>
                                            </div> */}
                                            <ul>
                                                <li>Developed responsive websites that work well on all devices and screen sizes.</li>
                                                <li>Connected APIs for smooth communication between frontend and backend.</li>
                                                <li>Helped boost website SEO for better visibility on search engines.</li>
                                                <li>Optimized React applications for better performance, ensuring faster load times and smoother functionality.</li>
                                                <li>Developed Django-based applications with robust backend architectures for secure and efficient data management.</li>
                                                <li>Created RESTful APIs using Django REST Framework for smooth communication with frontend systems.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="homeSecB">
                        <div className="container">
                            <div className="heading">
                                <h3>Projects</h3>
                                <p>PSD/Figma to HTML</p>
                            </div>
                            <div className="gridWrap">
                                <div className="grid">
                                    {PortfolioData &&
                                        PortfolioData.map((portfolio) => {
                                            const { url, image, title,alt } = portfolio;
                                            return (
                                                <div className="col" key={title}>
                                                    <Link to={url} target="_blank">
                                                        <figure><img src={require(`../assets/images/project/${image}`)} alt={alt} /></figure>
                                                        <figcaption>{title}</figcaption>
                                                        <div className="web-link">
                                                            <button type="button"><img src={require(`../assets/icon/browser.png`)} alt="codekmr" /></button>
                                                        </div>
                                                    </Link>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="inquirySec">
                        <div className="container">
                            <div className="inquirySecwrap">
                                <div className="heading">
                                    <h3>Get in Touch </h3>
                                </div>
                                <form id='form1' ref={form} onSubmit={sendEmail}>
                                    <div className="InptFld">
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="user_name" placeholder="Enter Your Name" autoComplete='off' required />
                                            <label>Name*</label>
                                        </div>
                                        <div className="form-group">
                                            <input className="form-control" type="email" name="user_email" placeholder="Enter Your Email" autoComplete="off" required />
                                            <label>Email*</label>
                                        </div>
                                        <div className="form-group">
                                            <input className='form-control' type="text" minLength="7" maxLength="15" placeholder='Enter Your Contact' name="user_contact" onChange={(e) => e.target.value = e.target.value.replace(/[^0-9+-]/g, '')} required autoComplete="off" />
                                            <label>Contact*</label>
                                        </div>
                                        <div className="form-group">
                                            <CustomSelect select="service" label="Where did you find my portfolio?" isrequired options={["Search Engine", "LinkedIn", "Instagram","Others"]} />
                                        </div>
                                        <div className="form-group message-bx flex100">
                                            <textarea className='form-control' name="user_message" maxLength="200" placeholder='Maximum Character 200' required />
                                            <label>Message*</label>
                                        </div>
                                    </div>
                                    <div className="btn"><input type="submit" value="Submit" /></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};
